<template>
  <div>
    <b-card title="Заёмщик">
      <b-card-text>
        <view-text-value title="ФИО" :value="borrower.getFullName()"></view-text-value>
        <view-text-value title="Дата рождения" :value="borrower.birthdate"></view-text-value>
        <view-text-value v-if="borrower.inn !== null" title="ИНН" :value="borrower.inn"></view-text-value>
        <view-text-value v-if="borrower.email !== null" title="E-mail" :value="borrower.email"></view-text-value>
      </b-card-text>
    </b-card>
    <b-card title="Паспортные данные">
      <b-card-text>
        <b-list-group>
          <view-text-value title="Серия" :value="borrower.passport.series" :simple="false"></view-text-value>
          <view-text-value title="Номер" :value="borrower.passport.number" :simple="false"></view-text-value>
          <view-text-value title="Дата выдачи" :value="borrower.passport.issueDate" :simple="false"></view-text-value>
          <view-text-value title="Место выдачи" :value="borrower.passport.issuePlace" :simple="false"></view-text-value>
          <view-text-value title="Код подразделения" :value="borrower.passport.issuer" :simple="false"></view-text-value>
        </b-list-group>
      </b-card-text>
    </b-card>
    <b-card title="Контактная информация">
      <current-date :timezone="borrower.locale" title="Дата и время по местному времени заёмщика"></current-date>
      <b-card-text>
        <b-table
            responsive
            striped
            hover
            :per-page="contactsTable.limit"
            :current-page="contactsTable.page"
            :fields="contactsTable.fields"
            :items="borrower.contacts"
        ></b-table>
        <b-pagination
            pills
            :total-rows="borrower.contacts.length"
            :per-page="contactsTable.limit"
            v-model="contactsTable.page"
        ></b-pagination>
      </b-card-text>
    </b-card>
    <b-card title="Адреса">
      <b-card-text>
        <b-tabs pills justified>
          <b-tab title="Адрес регистрации" :active="true">
            <address-block :address="borrower.addressRegister"></address-block>
          </b-tab>
          <b-tab title="Фактический адрес">
            <address-block :address="borrower.addressReal"></address-block>
          </b-tab>
        </b-tabs>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import ViewTextValue from "../ViewTextValue";
import Borrower from "../../mixins/models/borrower";
import AddressBlock from "./AddressBlock";
import CurrentDate from "../CurrentDate";
export default {
  name: "BorrowerBlock",
  props: {
    borrower: {
      type: Borrower,
      required: true,
    }
  },
  data() {
    return {
      showRegisterAddress: true,
      contactsTable: {
        page: 1,
        limit: 3,
        fields: [
          {
            key: 'type',
            label: 'Тип'
          },
          {
            key: 'name',
            label: 'Имя'
          },
          {
            key: 'phone',
            label: 'Телефон'
          }
        ]
      }
    }
  },
  components: {CurrentDate, AddressBlock, ViewTextValue}
}
</script>

<style scoped>

</style>