<template>
  <div>
    <b-card title="Займ">
      <b-card-text>
        <view-text-value title="Номер" :value="loan.id"></view-text-value>
        <view-text-value title="Дата выдачи" :value="loan.issueDate"></view-text-value>
        <view-text-value title="Плановая дата закрытия" :value="loan.plannedEnd"></view-text-value>
        <view-text-value title="Просрочка в днях" :value="loan.delayInDays"></view-text-value>
        <view-text-value title="Дата выхода в просрочку" :value="loan.delayDate"></view-text-value>
        <view-text-value title="Дата передачи" :value="loan.collectorInDate"></view-text-value>
        <view-text-value title="Сумма к возврату на плановую дату закрытия" :value="loan.sumToReturnOnPlannedEnd | currency"></view-text-value>
        <view-text-value title="Сумма к возврату (текущая)" :value="loan.sumToReturn | currency"></view-text-value>
        <view-text-value title="Остаток ОД" :value="loan.main | currency"></view-text-value>
        <view-text-value title="Остаток процентов" :value="loan.percent | currency"></view-text-value>
        <view-text-value title="Неустойка" :value="loan.penalty | currency"></view-text-value>
        <view-text-value title="Доп. услуги" :value="loan.additional | currency"></view-text-value>
        <view-text-value v-if="loan.lastPaymentDate !== null" title="Дата последней оплаты" :value="loan.lastPaymentDate"></view-text-value>
        <view-text-value v-if="loan.lastPaymentDate !== null" title="Сумма последней оплаты" :value="loan.lastPaymentSum | currency"></view-text-value>
        <view-text-value title="Согласие на взаимодействие с третьими лицами" :value="loan.thirdPerson ? 'Да' : 'Нет'"></view-text-value>
      </b-card-text>
    </b-card>
    <b-card title="Фактические платежи">
      <b-card-text>
        <b-table
            responsive
            striped
            hover
            :items="loan.payments"
            :fields="paymentsTable.fields"
            :current-page="paymentsTable.page"
            :per-page="paymentsTable.limit"
        >
          <template #cell(amount)="data">
            {{ data.value | currency }}
          </template>
        </b-table>
        <b-pagination
            pills
            v-model="paymentsTable.page"
            :per-page="paymentsTable.limit"
            :total-rows="loan.payments.length"
        ></b-pagination>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import Loan from "../../mixins/models/loan";
import Table from "../../mixins/table";
import ViewTextValue from "../ViewTextValue";

export default {
  name: "LoanBlock",
  components: {ViewTextValue},
  props: {
    loan: {
      type: Loan,
      required: true
    }
  },
  data() {
    return {
      paymentsTable: new Table({
        limit: 10,
        fields: [
          {
            key: 'confirmedAt',
            label: 'Дата платежа'
          },
          {
            key: 'amount',
            label: 'Сумма'
          },
          {
            key: 'status',
            label: 'Статус'
          }
        ]
      }),
    }
  },
  filters: {
    currency(val) {
      let curr = new Intl.NumberFormat('ru-RU')
      return curr.format(val)
    }
  },
}
</script>

<style scoped>

</style>