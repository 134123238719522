<template>
  <div>
    <div v-if="simple">
      <p><strong>{{ title }}: </strong> {{ value }}</p>
    </div>
    <div v-if="!simple">
      <b-list-group-item>
        <b-row>
          <b-col sm="6">
            {{ title }}:
          </b-col>
          <b-col sm="6" style="text-align: center">
            {{ value }}
          </b-col>
        </b-row>
      </b-list-group-item>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewTextValue",
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      required: true
    },
    simple: {
      type: Boolean,
      required: false,
      default: false,
    }
  }
}
</script>
