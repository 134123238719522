import Loan from "./models/loan";
import Borrower from "./models/borrower"

export default class LoanView {
    loan
    borrower

    constructor(data = {}) {
        this.loan = new Loan(data.loan || {})
        this.borrower = new Borrower(data.borrower || {})
    }
}