<template>
  <view-text-value :title="title" :value="date"></view-text-value>
</template>

<script>
import ViewTextValue from "./ViewTextValue";
export default {
  name: "current-date",
  components: {ViewTextValue},
  props: {
    timezone: {
      type: String,
      required: true
    },
    title: {
      type: String,
      default: 'Текущая дата и время'
    }
  },
  data() {
    return {
      date: null
    }
  },
  methods: {
    run() {
      let formatter = new Intl.DateTimeFormat([], {
        timeZone: this.timezone,
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      })
      this.date = formatter.format(new Date())
      this.date = this.date.replace(',', '')
      setTimeout(this.run, 20000)
    }
  },
  mounted() {
    this.run()
  }
}
</script>

<style scoped>

</style>