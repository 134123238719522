<template>
  <div>
    <view-text-value title="Регион" :value="address.region" :simple="simple"></view-text-value>
    <view-text-value v-if="address.area !== null" title="Район региона" :value="address.area" :simple="simple"></view-text-value>
    <view-text-value v-if="address.city !== null" title="Город" :value="address.city" :simple="simple"></view-text-value>
    <view-text-value v-if="address.cityDistrict !== null" title="Район города" :value="address.cityDistrict" :simple="simple"></view-text-value>
    <view-text-value v-if="address.settlement !== null" title="Населённый пункт" :value="address.settlement" :simple="simple"></view-text-value>
    <view-text-value v-if="address.street !== null" title="Улица" :value="address.street" :simple="simple"></view-text-value>
    <view-text-value title="Дом" :value="address.house" :simple="simple"></view-text-value>
    <view-text-value v-if="address.block !== null" title="Корпус" :value="address.block" :simple="simple"></view-text-value>
    <view-text-value v-if="address.flat !== null" title="Квартира" :value="address.flat" :simple="simple"></view-text-value>
  </div>
</template>

<script>
import Address from "../../mixins/models/address";
import ViewTextValue from "../ViewTextValue";

export default {
  name: "AddressBlock",
  components: {ViewTextValue},
  props: {
    address: {
      type: Address,
      required: true
    }
  },
  data() {
    return {
      simple: false
    }
  }
}
</script>

<style scoped>

</style>