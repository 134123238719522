export default class Address {
    region
    area
    city
    cityDistrict
    settlement
    street
    house
    block
    flat

    constructor(data) {
        this.region = data.region || null
        this.area = data.area || null
        this.city = data.city || null
        this.cityDistrict = data.cityDistrict || null
        this.settlement = data.settlement || null
        this.street = data.street || null
        this.house = data.house || null
        this.block = data.block || null
        this.flat = data.flat || null
    }
}