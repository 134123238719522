export default class Loan {
    id
    issueDate
    delayInDays
    plannedEnd
    delayDate
    fullCost
    sumToReturnOnPlannedEnd
    sumToReturn
    accounts
    main
    percent
    penalty
    additional
    payments
    thirdPerson
    collectorInDate
    lastPaymentDate
    lastPaymentSum

    constructor(data) {
        this.id = data.id || null
        this.issueDate = data.issueDate || null
        this.delayInDays = data.delayInDays || null
        this.plannedEnd = data.plannedEnd || null
        this.delayDate = data.delayDate || null
        this.fullCost = data.fullCost || null
        this.sumToReturnOnPlannedEnd = data.sumToReturnOnPlannedEnd || null
        this.sumToReturn = data.sumToReturn || null
        this.main = data.main || null
        this.percent = data.percent || null
        this.penalty = data.penalty || null
        this.additional = data.additional || null
        this.thirdPerson = data.thirdPerson || null
        this.collectorInDate = data.collectorInDate || null
        this.lastPaymentDate = data.lastPaymentDate || null
        this.lastPaymentSum = data.lastPaymentSum || null
        let accounts = []
        for (let a in (data.accounts || [])) {
            accounts.push(new Account(data.accounts[a]))
        }
        let payments = []
        for (let p in (data.payments || [])) {
            payments.push(new Payment(data.payments[p]))
        }
        this.accounts = accounts
        this.payments = payments
    }
}

class Payment {
    id
    amount
    status
    confirmedAt
    type

    constructor(data) {
        this.id = data.id || null
        this.amount = data.amount || null
        this.status = data.status || null
        this.confirmedAt = data.confirmedAt || null
        this.type = data.type || null
    }
}

class Account {
    balance
    type

    constructor(data) {
        this.balance = data.balance || null
        this.type = data.type || null
    }
}
