<template>
  <div>
    <div v-if="loading"><b-spinner></b-spinner></div>
    <b-container fluid v-if="!loading">
      <p class="text-danger" v-if="error">{{ error }}</p>
      <div v-if="error === null">
        <b-row style="text-align: center; margin-top: 1%"><h3>Займ №{{ model.loan.id }}</h3></b-row>
        <b-row style="margin-top: 1%">
          <b-col sm="6">
            <loan-block :loan="model.loan"></loan-block>
          </b-col>
          <b-col sm="6">
            <borrower-block :borrower="model.borrower"></borrower-block>
          </b-col>
        </b-row>
      </div>
    </b-container>
  </div>
</template>

<script>
import rpc from "../rpc";
import LoanView from "../mixins/loan-view";
import AuthErrorHandler from "../mixins/auth-error-handler";
import LoanBlock from "../components/collector/LoanBlock";
import BorrowerBlock from "../components/collector/BorrowerBlock";

export default {
  name: "CollectorLoan",
  components: {BorrowerBlock, LoanBlock},
  data() {
    return {
      loading: true,
      error: null,
      model: new LoanView()
    }
  },
  methods: {
    getLoan() {
      this.loading = true
      let id = this.$router.currentRoute.params['id']
      rpc.getLoan(id).then((result) => {
        this.model = new LoanView(result)
        this.loading = false
      }).catch((err) => {
        AuthErrorHandler.handleAuthError(err)
        if (err.code === -32001) {
          this.$router.push('/')
        }
        this.error = err.message
        this.loading = false
      })
    }
  },
  mounted() {
    this.getLoan()
  }
}
</script>

<style scoped>

</style>