import Address from "./address";

export default class Borrower {
    surname
    name
    patronymic
    birthdate
    email
    inn
    passport
    addressRegister
    addressReal
    contacts
    locale

    constructor(data) {
        this.surname = data.surname || null
        this.name = data.name || null
        this.patronymic = data.patronymic || null
        this.birthdate = data.birthdate || null
        this.email = data.email || null
        this.inn = data.inn || null
        this.passport = new Passport(data.passport || {})
        this.addressRegister = new Address(data.addressRegister || {})
        this.addressReal = new Address(data.addressReal || {})
        let contacts = []
        for (let i in (data.contacts || [])) {
            contacts.push(new Contact(data.contacts[i]))
        }
        this.contacts = contacts
        this.locale = data.locale || 'Europe/Moscow'
    }

    getFullName() {
        let fullName = this.surname + ' ' + this.name
        if (this.patronymic !== null) {
            fullName = fullName + ' ' + this.patronymic
        }
        return fullName
    }
}

class Contact {
    type
    name
    phone

    constructor(data) {
        this.type = data.type || null
        this.name = data.name || null
        this.phone = data.phone || null
    }
}

class Passport {
    series
    number
    issueDate
    issuePlace
    issuer

    constructor(data) {
        this.series = data.series || null
        this.number = data.number || null
        this.issueDate = data.issueDate || null
        this.issuePlace = data.issuePlace || null
        this.issuer = data.issuer || null
    }
}
